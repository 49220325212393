import { Box, Image, Text } from '@chakra-ui/react';
import React, { useRef, useState } from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
const CardCarousel = () => {
    const images = [
        {
            image:"./ourwork3.jpg",
            text:'',
            href:""

        },
        {
            image:"./ourwork2.jpg",
            text:'',
            href:""

        },
        {
            image:"./ourwork4.jpg",
            text:'',
            href:""

        },
        {
            image:"./ourwork5.jpg",
            text:'',
            href:""

        },
        {
            image:"./ourwork6.jpg",
            text:'',
            href:""

        },
        {
          image:"./ourwork7.jpg",
          text:'',
          href:""

      },
      {
        image:"./ourwork8.jpg",
        text:'',
        href:""

    },
    {
      image:"./ourwork9.jpg",
      text:'',
      href:""

  },
  {
    image:"./ourwork1.jpg",
    text:'',
    href:""

},   
        
        
        
        
        
      ];
      const [isHovered, setIsHovered] = useState(false);

      const handleHover = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
      const carouselRef = useRef(null);
  return (
    <Box marginTop={'20px'} padding={'8px'}>
        <Carousel responsive={responsive}
       infinite={true}
       ref={carouselRef}
       autoPlay 
       autoPlaySpeed={2000}
       arrows={false}
     
    
        >
       
  {images.map((el, index) => (
    <Box>
    
    <Box
    display="inline-block"
    position="relative"
    borderRadius={'5px'}
    overflow="hidden"
    width={{base:'97%',lg:'97%'}}
   height={{base:'250px',lg:'250px'}}
    transition="transform 0.3s"
    onMouseEnter={handleHover}
    onMouseLeave={handleMouseLeave}
    _hover={{
      transform: "scale(1.02)",
    }}
    marginBottom={'10px'}
  >
    <Image src={el.image} alt="Image" width="100%" height="100%" objectFit="cover" />
    
  </Box>
  <Text fontSize={{base:'16px',lg:'16px'}} color={'black'} ></Text>

  </Box>
  ))}


 
 
</Carousel>
    </Box>
  )
}

export default CardCarousel