import React, { useState, useEffect } from 'react';
import { Box, Image, Flex } from '@chakra-ui/react';

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrolledUp = prevScrollPos > currentScrollPos;

      setVisible((prevVisible) => {
        const isTop = currentScrollPos === 0;
        const shouldShow = isTop || isScrolledUp;

        setPrevScrollPos(currentScrollPos);
        return shouldShow;
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <Box
      as="nav"
      height={visible ? '150px' : '0'}
      transition="height 0.3s ease-in-out"
      position="fixed"
      top="0"
      width="100%"
      bg="gray.200"
      zIndex="999"
     
    >
      <Flex justifyContent="center" alignItems="center" height="100%">
        {visible && (
          <Image src="floofyhugs.png" alt="Floofy hugs"  />
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
