import { Box, Text } from "@chakra-ui/react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialCarousel = () => {
  return (
    <Box maxWidth={{base:'95%',lg:'50%'}} margin="auto">
      <Carousel
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay
        interval={5000}
        infiniteLoop
        swipeable
      >
        <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">"We did the grooming for our Shih Tzu. It was “the best” service we could have ever asked for. They took excellent care of the puppy ( they puppy recently had knee surgery). Carefully removed all the matte. And the price was so reasonable. Honestly I was surprised with their service. I would recommend this for anyone who wants to groom their fur baby."<b>-senthil kumar Jeyaeasan</b></Text>
        </Box>
        <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">"Sarjapur pet parents,  get ready... we have got a professional pet spa here...Wonderful cute ambience,  neat set up for grooming... Wonderful friendly staff and excellent owners who really know what they are doing...

Thanks a lot floofy hugs.. A big hug from our family and elfo." <b>-Radhika Karthick</b></Text>
        </Box>
        <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">"A Big Thanks from Shifu & Family,
Taking our pet for regular grooming sessions for the past 2 years has been taxing owing to the travel distance from Sarjapur to the city side between our hectic schedules. It was a blessing in disguise seeing a new pet grooming studio open near us welcome to “Floofy hugs”.<b>-HariniGeetha Goka</b></Text>
        </Box>
        <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">
          Can’t explain how excited I was when I saw their pamphlet coz it was becoming a mammoth task to take my li’l boy all the way to varthur for his grooming session…Although it was just their 3 rd day of opening I wanted to give it a try & I must say I was totally impressed."<b>-Achanta Susmita</b>
            </Text>
          </Box>
          <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">
          "A Big thanks from Mia and June.... Good job very well done with a lot of patients. Thanks you."<b>-Rajeev Kumar</b>
            </Text>
          </Box>
          <Box p={4}  rounded="md" fontStyle={'italic'}>
          <Text fontSize="lg">
          "Took my 8 years young boy to this new place for grooming. This is the first time I tried a new place other than his usual groomer since childhood and I’m really amazed with the entire experience of interacting with the owners to getting Koko groomed. The groomers did a good job considering Koko’s hair was matted at few places and he generally doesn’t allow new folks groom him.

Thanks team 😊"<b>-Abhishek Kulshrestha</b>
            </Text>
          </Box>
          
      </Carousel>
    </Box>
  );
};

export default TestimonialCarousel;
